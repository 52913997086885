import React, {Component} from 'react';
import Image from '../ResuableImageWrapper';
import styled from 'styled-components';
import CountUp, {startAnimation} from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
// import { useInView } from 'react-intersection-observer';
import '../../../sass/components/reuseable_components/_multiple_stats_image.scss'

const Logo = styled.div`
  width:${(props) => props.widthOverride} !important;
`

const ParallaxWrapper = styled.section`
background:linear-gradient(to top, ${(props) => props.bgCol1}, ${(props) => props.bgCol2}) !important;
`


class CaseStudy6StatisticBgImage extends Component {
  
  state = {
    didViewCountUp: false,
    isInView: false,
  };

  componentDidMount() {
    if(this.props.isInView === 'is_inview'){
        this.setState({
          wasInView: true
        })
      }
  }

  

  
  render() {
    let classList = [
      'multiple_stats_image',
      'contains-custom-colour'
    ]; 
    
    const inviewOnChange = (isVisible) => {
      //console.log(isVisible)
      this.setState({
        isInView: isVisible,
        didViewCountUp: true
      })
    }
    const removeBottomMargin = this.props.removeBottomMargin;
    const stats = this.props.statistics;
  
    if(removeBottomMargin === true){
      classList.push('no-margin');
    }

    if(this.props?.backgroundImage?.localFile === undefined){
      classList.push('no_image');
    }
    
    return (
      <VisibilitySensor scrollCheck={true} delayedCall={true} partialVisibility={true} offset={{bottom:120}} active={!this.state.isInView} onChange={inviewOnChange}>
        {({isVisible}) =>
          <section className={`${ this.state.isInView === true ? 'is_inview' : ''} ${classList.join(" ") }`}>
            <div className="wrapper">
              <div className="title">{this.props.heading}</div>	
              <div className="stats_wrap">
                {
                  stats.map((stat) => {
                    return(
                      <div className={stat.addColourBackground ? "stat-info bg" : "stat-info"}>
                        <span className="stat">
                          <CountUp 
                            className="number" 
                            start={0} 
                            duration={5} 
                            suffix={stat.symbol} 
                            end={this.state.isInView  ? stat.number : 0}
                            >
                          </CountUp>
                          
                        </span>
                        <div className="label">{stat.label}</div>
                      </div>
                    )
                  })
                }
                
              </div>
            </div>
            {
            this.props?.backgroundImage?.localFile !== undefined ? 
              <div className="image-wrap-outer bg">
                
                <Image 
                image={this.props?.backgroundImage?.localFile?.childImageSharp?.fluid} 
                alt={this.props?.backgroundImage?.altText}
                />
              </div>
            : "" 
            }
          </section>
        }
      </VisibilitySensor>
    )
  }
}

export default CaseStudy6StatisticBgImage;


