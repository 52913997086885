import React, {useState} from 'react';
import Image from '../ResuableImageWrapper';
import LottieWrapVideo from '../../lottie/lottieWrapVideo';
import parse from 'html-react-parser'
import CountUp, {startAnimation} from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_text_stat_bg_image.scss";
import "../../../sass/components/reuseable_components/_stat_block.scss";

export default function CaseStudyTextStatisticBgImage(data, {isInView}) {
  const [didViewCountUp, setDidViewCountUp] = useState(false)
  const [isInview, setIsInview] = useState(false);
 
  const inviewOnChange = isVisible => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
    setIsInview(isVisible)
  }
  let classList = [
    'text_stat_bg_image',
    'contains-custom-colour'
  ]; 
  
  const removeBottomMargin = data?.removeBottomMargin;

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
  return (
    <VisibilitySensor scrollCheck={true} delayedCall={true} partialVisibility={true} offset={{bottom:120}} active={!isInview} onChange={inviewOnChange}>
    {({isVisible}) =>
      <section className={`${classList.join(" ")} ${isInview === true ? 'is_inview' : ''}`}>
        <div className="max-width-wrapper clearfix">
          <div className="wrapper-900">
            <div className="text-area">
              <div className="text">{parse(data.text)}</div>
            </div>
            
            <div className={`stat-block ${isInView === true ? 'is_inview' : ''}`}>
              <div className="stat-info">
                <span className="stat">         
                    <CountUp 
                    className="number" 
                    start={0} 
                    duration={5} 
                    suffix={data.statisticGatsby.symbol} 
                    end={didViewCountUp === true ? data.statisticGatsby.number : 0}
                    >
                  </CountUp>
                </span>
                <div className="label">{data.statisticGatsby.label}</div>
              </div>
              <div className="stat-graphic">
                <LottieWrapVideo animation="arrow" checkIsInview={true} isInview={isInview ? true : false}></LottieWrapVideo> 
                <div className="pounds"><span>£</span><span>£</span><span>£</span></div>
              </div>
            </div>
          </div>
          <div className="bg_image_fade">
              <Image 
              image={data?.backgroundImage?.localFile?.childImageSharp?.fluid} 
              alt={data?.backgroundImage?.altText}
              />
          </div>
        </div>
      </section>
    }
  </VisibilitySensor>
  )
}
