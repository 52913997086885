import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import useInView from "react-cool-inview";

const OuterWrapper = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
`;


const Wrapper = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;

    video{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;

        &.cover{
        object-fit: cover;
        }

    } 

    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (max-width: 1250px) {
        justify-content: center;
      }
      
    }
`;


const LottieWrapVideo = (data) => {
  const videoRef = useRef();
  // useEffect(() => {

  // }, [])

  if(data.addCountdownDelay === true){
    setTimeout(function(){
      videoRef.current.play();
    }.bind(this),8000)
  }else{
    if(data.isInview){
      videoRef.current.play();
    }
  }

  // console.log(data.isInview);


  
  const autoPlay =  data.checkIsInview === true && data.addCountdownDelay !== true  ? false 
                    : data.addCountdownDelay === true ? false  
                    : true;

  return (
    <OuterWrapper 
    className="lottie-wrapper--outer"
    // onMouseEnter={hoveringStateFunc}
    // onMouseLeave={hoveringStateFunc}
        >  
      <Wrapper className="lottie-wrapper--inner" >
        <video 
        ref={videoRef} 
        playsInline 
        muted 
        autoPlay={autoPlay} 
        className={
          data.animation === 'arrow'  
          ? 'cover' 
          : '' 
          || data.animation === 'career_seo' 
          || data.animation === 'career_cro' 
          || data.animation === 'career_ppc' 
          || data.animation === 'career_content' 
          || data.animation === 'career_copy'
          ? 'contain' 
          : '' 
        }>
          {data.animation === 'arrow' ? 
            <source src='https://player.vimeo.com/external/563637457.sd.mp4?s=38228100903bdfc2fbe8f2731349247ede8d8713&profile_id=165' type="video/mp4"></source>
          :
          data.animation === 'career_seo' ? 
            <source src='https://player.vimeo.com/external/563177204.sd.mp4?s=a7d54fee8ae43048f55e312794d5df6d5f0c9594&profile_id=165' type="video/mp4"></source>
          :
          data.animation === 'career_cro' ? 
            <source src='https://player.vimeo.com/external/563247437.sd.mp4?s=c5c356baed419a4aad55a52b3abbd57b3d4212c0&profile_id=165' type="video/mp4"></source>
          :
          data.animation === 'career_ppc' ? 
            <source src='https://player.vimeo.com/external/563247459.sd.mp4?s=bc572a2893c7299294b92bee75f23ed35aa6f10e&profile_id=165' type="video/mp4"></source>
          :
          data.animation === 'career_content' ? 
            <source src='https://player.vimeo.com/external/563247237.sd.mp4?s=d6efa70c3f65f974583c6f225c28162b4e0a7f5c&profile_id=165' type="video/mp4"></source>
          :
          data.animation === 'career_copy' ? 
            <source src='https://player.vimeo.com/external/563247324.sd.mp4?s=9c540efdbe81b70de5591e34662001039c5df2c2&profile_id=165' type="video/mp4"></source>
          :
            <source src={data.video} type="video/mp4"></source>
          }
        </video>
      </Wrapper>
    </OuterWrapper>
  );
};

export default LottieWrapVideo;