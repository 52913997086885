import React, { useRef, useEffect, useState } from "react"
import RellaxWrapper from 'react-rellax-wrapper'
import Image from '../ResuableImageWrapper';
import styled from 'styled-components';
import parse from 'html-react-parser'
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_websitePreview_text.scss";


const Logo = styled.div`
  width:${(props) => props.widthOverride} !important;

`

const ParallaxWrapper = styled.section`
background:linear-gradient(to top, ${(props) => props.bgCol1}, ${(props) => props.bgCol2}) !important;
position: relative;
float: left;
`


const CaseStudyParallaxImageText = (data, { isInView }) => {
  
  let classList = [
    'websitePreview_text',
    // 'is_inview'
  ]; 

  const removeBottomMargin = data.removeBottomMargin;
  const wrapper = useRef()

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  function parallax () {
    const wrapperTop = wrapper.current.getBoundingClientRect().top
    const wrapperHeight = wrapper.current.getBoundingClientRect().height
    const windowHeight = window.innerHeight
    const image = wrapper.current.querySelector('.scrolling_image');
  
    if(wrapperTop <= windowHeight){
      image.style.transform = `translate3d(0px, ${wrapperTop * 0.2}px, 0px)`;
    }
  }
  useEffect(() => {
    if(typeof window !== 'undefined'){
      window.addEventListener('scroll', parallax, {passive: true})
    }
    return () => {
      window.removeEventListener('scroll', parallax, {passive: true})
    }
  }, [])
  

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
      
        <ParallaxWrapper ref={wrapper} className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}  bgCol1={data.backgroundColour1} bgCol2={data.backgroundColour2}>
          <div className="wrapper-large clearfix">
            <RellaxWrapper wrapper='#container-scroller'  
              speed={3.6} 
              center={true} 
              horizontal={false} 
              vertical={true} 
              round={true}
              // relativeToWrapper={true}
              // wrapper={'.websitePreview_text .wrapper-large'}
              >
                <div className="scrolling_image rellax">
                  <Image 
                  image={data?.parallaxImage?.localFile?.childImageSharp?.fluid} 
                  alt={data?.parallaxImage?.altText}
                  />
                </div>
            </RellaxWrapper>

            <div className="wrapper clearfix">
              <div className="text">
                  {parse(data.text)}
              </div>
            </div>
          </div>
        </ParallaxWrapper>
      
      }
    </VisibilitySensor>
  )
}

export default CaseStudyParallaxImageText

