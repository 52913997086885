import React, {useState} from "react"
import parse from 'html-react-parser';
import {Link} from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_cta-section.scss";

const BasicCallToAction  = (data) => {
  const heading = data?.callToActionGatsby?.ctaHeading || data?.heading
  const removeBottomMargin = data.removeBottomMargin
  const buttons = data?.callToActionGatsby?.ctaButtons || data?.buttons
  const autoHeight = data.autoHeight
  
  let classList = [
    'cta-section',
    'section',
    'orange-grad',
    'text-center'
  ]; 

  // console.log('removeBottomMargin', data )
  
  
  if(removeBottomMargin === true){
    classList.push('no-margin');
  }

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''} ${autoHeight === true ? 'autoHeight' : ''}`} data-bg-shape="show_shape">
          <div className="wrapper clearfix">
            <div className="cta-inner">
              {heading ? parse(heading) : ''}
            </div>
            
            <div className="button-wrap">
              {buttons?.map((button) => {
                return(
                  <Link to={button.pageLink.link} className="button button-capsule"><h2>{button.buttonText}</h2></Link>
                )
              })}
            </div>
            
          </div>
        </section>
      }
    </VisibilitySensor>
  )
}

export default BasicCallToAction 
