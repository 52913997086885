import React, {useState, useEffect} from "react"
import RellaxWrapper from 'react-rellax-wrapper'
import Image from '../ResuableImageWrapper';
import styled from 'styled-components';
import {Link} from 'gatsby';
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_intro.scss";
import "../../../sass/pages/components/_hero.scss";

gsap.registerPlugin(SplitText);


const Logo = styled.div`
  width:${(props) => props.widthOverride} !important;

`

const BgImage = styled.div`
  opacity:${(props) => props.opacity} !important;
`

const CaseStudyIntro = (data, { case_study, isInView }) => {
  // console.log(data)
  // const cats = data.case_study.caseCats.nodes;
  // console.log(pageContext)
  
  let classList = [
    'case_intro',
    // 'is_inview'
  ]; 

  const removeBottomMargin = data.removeBottomMargin;

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  useEffect(() => {
    let mySplitText = new SplitText('.case_intro h1 .split', {type:"lines", linesClass:'lines'});
    
    mySplitText.lines.map(line => {
      let grandparent = line.parentNode.parentNode;
      let parent = line.parentNode;
      let originalHtml = line.innerHTML;
      let newHtml = `<div>${originalHtml}</div>`
      
      line.innerHTML = newHtml;

      grandparent.append(line);
    
      Array.from(grandparent.querySelectorAll('.lines')).map(line => {
        setTimeout(function(){
          line.classList.add('is_inview')
        }.bind(this), 1);
      })

      parent.style.display = 'none';
    })

  }, [])
  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
      
      <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} >
        <div className="hero-image-wrapper">
          <div className="text-wrap--outer">
            <div className="text_wrap">
                {data?.logo?.localFile?.childImageSharp?.fluid ? 
              <Logo className="logo" widthOverride={data.logoWidth+'px'}>
                <Image 
                image={data?.logo?.localFile?.childImageSharp?.fluid} 
                alt={data?.logo?.altText}
                /> 
              </Logo>
              : ''}
              <h1><div className="split">{data.heading}</div></h1>
              <div className="divider"></div>
              {/* <ul>
                {
                  cats?.map((cat) => {
                    return(
                      <li className={cat.slug}><Link to={cat.link}><span className="text">{cat.name}</span><span className="line"></span></Link></li>
                    )
                  })                    }
              </ul> */}
            </div>
          </div>

          <RellaxWrapper wrapper='#container-scroller'  speed={-2.6} className="rellaxWrapper">
            <BgImage className="image-outer rellax" opacity={data.backgroundImageOpacity/100}>
              {data?.backgroundImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
              <Image image={data?.backgroundImage?.localFile?.childImageSharp?.fluid} focalPoint={data.backgroundImageFocalPoint}/>
            </BgImage>
          </RellaxWrapper>
        </div>
      </section>
      
      }
    </VisibilitySensor>
    
  )
}

export default CaseStudyIntro

